
































































































































import {
  SfMegaMenu,
  SfProductCard,
  SfScrollable,
  SfMenuItem,
  SfButton,
} from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useImage, useProduct } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'SearchResults',
  components: {
    SfMegaMenu,
    SfProductCard,
    SfScrollable,
    SfMenuItem,
    SfButton,
    SvgImage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    searchResults: {
      type: Array as PropType<Product[] | null>,
      default: () => [],
    },
  },
  setup() {
    const { getMagentoImage, imageSizes } = useImage();
    const { getProductPath } = useProduct();

    return {
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchResults && _vm.searchResults.length > 0)?_c('SfMegaMenu',{staticClass:"search",attrs:{"visible":_vm.visible,"title":_vm.$t('Search results')}},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.searchResults && _vm.searchResults.length > 0)?_c('div',{key:"results",staticClass:"search__wrapper-results"},[_c('SfMegaMenuColumn',{staticClass:"sf-mega-menu-column--pined-content-on-mobile search__results",attrs:{"title":_vm.$t('Product suggestions')},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
return [_c('SfMenuItem',{staticClass:"sf-mega-menu-column__header search__header",attrs:{"label":title},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("\n                ​\n              ")]},proxy:true}],null,true)})]}}],null,false,3075151688)},[_vm._v(" "),_c('SfScrollable',{staticClass:"results--desktop desktop-only",attrs:{"show-text":"","hide-text":""}},[_c('div',{staticClass:"results-listing"},_vm._l((_vm.searchResults),function(product,index){return _c('SfProductCard',{key:index,staticClass:"result-card",attrs:{"image-tag":"nuxt-img","regular-price":_vm.$fc(_vm.productGetters.getPrice(product).regular),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"image":_vm.getMagentoImage(
                    _vm.productGetters.getProductThumbnailImage(product)
                  ),"nuxt-img-config":{
                  fit: 'cover',
                },"alt":_vm.productGetters.getName(product),"title":_vm.productGetters.getName(product),"link":_vm.localePath(_vm.getProductPath(product)),"wishlist-icon":false}})}),1)]),_vm._v(" "),_c('div',{staticClass:"results--mobile smartphone-only"},_vm._l((_vm.searchResults),function(product,index){return _c('SfProductCard',{key:index,staticClass:"result-card",attrs:{"image-tag":"nuxt-img","regular-price":_vm.$fc(_vm.productGetters.getPrice(product).regular),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(product),"reviews-count":_vm.productGetters.getTotalReviews(product),"image-width":_vm.imageSizes.productCardHorizontal.width,"image-height":_vm.imageSizes.productCardHorizontal.height,"image":_vm.getMagentoImage(
                  _vm.productGetters.getProductThumbnailImage(product)
                ),"nuxt-img-config":{
                fit: 'cover',
              },"alt":_vm.productGetters.getName(product),"title":_vm.productGetters.getName(product),"link":_vm.localePath(_vm.getProductPath(product)),"wishlist-icon":false}})}),1)],1),_vm._v(" "),_c('div',{staticClass:"action-buttons smartphone-only"},[_c('SfButton',{staticClass:"action-buttons__button color-light",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n            "+_vm._s(_vm.$t('Cancel'))+"\n          ")])],1)],1):_c('div',{key:"no-results",staticClass:"before-results"},[_c('SvgImage',{staticClass:"before-results__picture",attrs:{"icon":"error_image","label":_vm.$t('Error'),"width":"412","height":"412"}}),_vm._v(" "),_c('p',{staticClass:"before-results__paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t('You haven’t searched for items yet'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"before-results__paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t('Let’s start now – we’ll help you'))+"\n        ")]),_vm._v(" "),_c('SfButton',{staticClass:"before-results__button color-secondary smartphone-only",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n          "+_vm._s(_vm.$t('Go back'))+"\n        ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }